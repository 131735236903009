<template>
  <div class="stack">
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest" style="margin-top: 0">
      <div class="stack-info__content">
        <page-title class="stack-info__title">
          {{ $t('title.server') }}
        </page-title>
        <div :class="{ visible: true }" class="stack-info__create">
          <base-button
            :icon="isIcon"
            :tooltip="{
              content: text,
              autoHide: false,
              placement: 'auto',
              container: false,
              trigger: 'click hover',
            }"
            :color="isColor"
            :disabled="isRequest"
            class="stack-info__create-btn"
            @click="isPrivateNetwork !== 0 ? newPrivateNetwork() : ''"
            >{{ $t('newServer') }}</base-button
          >
        </div>
      </div>
      <base-empty v-if="list.length === 0" title="Новая приватная сеть" class="cloud-info__empty">
        <template #link>
          <base-button>
            {{ $t('newServer') }}
          </base-button>
        </template>
      </base-empty>
      <div v-else>
        <tariffs-table-network :table-head="tableHead" />
      </div>
    </page-block>
  </div>
</template>

<script>
import TariffsTableNetwork from '../../Main/components/TariffsTableNetwork';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
import newPrivateNetwork from '@/mixins/newPrivateNetwork';
export default {
  name: 'ViewNetwork',
  components: {
    TariffsTableNetwork,
    BaseEmpty,
  },
  mixins: [storeMixin, newPrivateNetwork],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            minWidth: '180px',
            maxWidth: '300px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'subnet',
          label: this.$t('tableHead.subnet'),
          style: {
            minWidth: '130px',
            maxWidth: '150px',
          },
        },
        {
          key: 'conditions',
          label: this.$t('tableHead.conditions'),
          style: {
            minWidth: '130px',
            maxWidth: '134px',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            minWidth: '100px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            minWidth: '44px',
            textAlign: 'center',
          },
        },
      ],
      isRequest: true,
      diskId: '',
    };
  },
  computed: {
    isIcon() {
      return this.isPrivateNetwork === 0 ? 'error' : '';
    },
    isColor() {
      return this.isPrivateNetwork === 0 ? 'warn' : 'primary';
    },
    text() {
      if (this.isPrivateNetwork === 0) {
        return `Вы достигли квоты по количеству приватных сетей.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else {
        return 'Создать новую приватную сеть';
      }
    },
    isPrivateNetwork() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.network
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.network.limit -
          this.$store.state.moduleStack.quotasNetwork.network.used
        );
      } else return 0;
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(() => {
        this.newFetch();
      })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
    // this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'volumes').then(data => {
    //   let volumeId = data.volumes
    //     .filter(x => x.status === 'creating' || x.status === 'queued')
    //     .map(x => x.id);
    //   if (volumeId.length > 0) {
    //     volumeId.forEach(x => {
    //       console.log(x);
    //       this.diskId = x;
    //       this.updateDiskStatus(_, x);
    //     });
    //   }
    // });
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
    // async updateDiskStatus(callback, payload) {
    //   this.timerId = setTimeout(() => {
    //     this.$store
    //       .dispatch('moduleStack/checkDiskStatus', {
    //         disk: this.diskId,
    //         type: 'volumes',
    //         silent: true,
    //       })
    //       .then(async data => {
    //         if (['extending'].includes(data.status)) {
    //           this.res = data.status;
    //           await this.updateDiskStatus(callback, payload);
    //         } else {
    //           // console.log(data);
    //           this.timerCnt = 0;
    //           this.isProcessing = false;
    //           clearTimeout(this.timerId);
    //           this.res = data.status;
    //           return 'success';
    //         }
    //       });
    //   }, 1000 * this.timerCnt);
    // },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Приватная сеть",
      "stats": "Статистика"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "tableHead": {
      "name": "Сеть",
      "type": "Тип",
      "conditions": "Состояние",
      "shared": "Публичная",
      "config": "Конфигурация",
      "status": "Статус",
      "subnet": "Подсеть",
      "menu": "Меню"
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "newServer": "Новая сеть",
    "quotaNetwork": "Вы достигли квоты по количеству приватных сетей, обратитесь в поддержку для изменения квоты",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        flex: 1 1 auto;
      }
    }
  }

    &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-bottom: 1.5 rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }
    &__create {
        display: flex;
        flex-direction row;
        align-content flex-end;
        align-items baseline;
        //flex: 1 1 auto;
        margin-top: 1.5rem;
        max-width: 200px;
        &-btn {
          margin-top: 20px
          margin-right: 0;
          +breakpoint(ms-and-up) {
            margin-top: 40px;
          }
        }
        &-hint {
          margin: -0.25rem 0.25rem;
          vertical-align: middle;
          &-quota{
            margin: 20px 0 0 0;
            position: relative;
            padding: 1em 1.5rem;
            border-radius: 6px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            background: #0f68e4;
            color: #fff;
            border: 1px solid transparent;
            opacity: 0.2;
          +breakpoint(ms-and-up) {
            margin-top: 40px;
          }

          }

        }

        +breakpoint(sm-and-up) {
          margin: 0 0 0 auto;
        }
      }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
