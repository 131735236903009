import NewPrivateNetwork from '@/layouts/Stack/components/NewPrivateNetwork';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';

export default {
  mixins: [showErrorOpenStackModal, storeMixin],
  data() {
    return {
      data: {},
      timerId: '',
      networkId: '',
      isOk: false,
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    newPrivateNetwork: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'NewPrivateNetwork';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: NewPrivateNetwork,
          closeOnBackdrop: false,
          props: {
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instance = data;
              // console.log(instance);
              if (instance.net) Vue.set(this.modal.footer.confirm.props, 'disabled', false);
              else Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
            notready: () => {
              // console.log('notready');
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  this.addNewNetwork({ name: instance.net })
                    .then(async data => {
                      console.log(data);
                      // if (data)
                      this.networkId = data && data.network ? data.network.id : '';
                      if (this.networkId) this.isOk = true;
                      await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
                      await this.$store.dispatch('moduleStack/fetchQuotasNetwork', this.project);
                      if (instance.enabled === 'off') {
                        that.$modals.close();
                        this.networkId = '';
                        this.showResModal('Сеть успешно создана.');
                      } else {
                        this.isOk = false;
                        this.addNewSubnet(data.network.id, instance).then(async data => {
                          // console.log(data);
                          if (data) this.isOk = true;
                          await this.$store.dispatch(
                            'moduleStack/fetchOpenStackRequest',
                            'subnets'
                          );
                          await this.$store.dispatch(
                            'moduleStack/fetchQuotasNetwork',
                            this.project
                          );
                          await this.$store.dispatch(
                            'moduleStack/fetchOpenStackRequest',
                            'networks'
                          );
                        });
                        this.$modals.close();
                        if (this.isOk) this.showResModal('Сеть успешно создана.');
                        // console.log('-------------');
                      }
                    })
                    .catch(e => {
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    addNewNetwork(payload) {
      return this.$store.dispatch('moduleStack/createNetwork', payload).catch(e => {
        this.showError(e);
      });
    },
    deleteCurrentNetwork(net) {
      return this.$store
        .dispatch('moduleStack/deleteNetwork', {
          net: net,
        })
        .catch(e => e);
    },
    async addNewSubnet(id, payload) {
      const params = {
        id: id,
        ...payload,
      };
      // if (payload.dhcp==='on') params.dnsNames = this.$store.state.moduleStack.dnsDefault
      return this.$store.dispatch('moduleStack/createSubnet', params).catch(async e => {
        this.isOk = false;
        await this.deleteCurrentNetwork(this.networkId);
        this.showError(e);
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        confirm: {
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
